import React, { useEffect, useState } from "react";
import { Progress, Tooltip } from "antd";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { LoadingProcessData } from "react-pdf";
import { AppPagination } from "../app-pagination/AppPagination";
import { useDispatch } from "react-redux";
import "./pdf-content.scss";
import { updatePage } from "../../store";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";

interface Props {
  pageInfo?: {
    width?: number;
    height?: number;
    scale?: number;
  };
  displayPagination?: boolean;
  currPage: number;
  setCurrPage: (page: number) => void;
  pdf: any;
}

export const PdfViewer = (props: Props) => {
  const { pdf, pageInfo, displayPagination, currPage, setCurrPage } = props;
  const [totalPages, setTotalPages] = useState(0);
  const [loadSuccess, setLoadSuccess] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState({
    loaded: 0,
    total: 100,
  } as LoadingProcessData);
  const dispatch = useDispatch();
  const displayBottomPagination = displayPagination && loadSuccess;
  const [scale, setScale] = useState(pageInfo?.scale || 1.1);
  const zoomIn = () => setScale((prevScale) => prevScale + 0.2);
  const zoomOut = () => setScale((prevScale) => Math.max(0.4, prevScale - 0.2));

  const onDocumentLoadSuccess = (params: any) => {
    const { numPages } = params;
    setTotalPages(numPages);
    setLoadSuccess(true);
  };
  useEffect(() => {
    dispatch(updatePage(currPage));
  }, [currPage]);

  return (
    <div className="e-pdf e-content">
      {displayBottomPagination && (

        <div className="flex-center e-pdf-pagination">
          <AppPagination
            total={totalPages * 10}
            simple
            current={currPage}
            onChange={(pageVal) => setCurrPage(pageVal)}
            showSizeChanger={false}
          />
         <div className="zoom-controls">
         <Tooltip title="Zoom Out">
           <ZoomOutOutlined className="zoom-icon" onClick={zoomOut} />
         </Tooltip>
         <span className="zoom-percentage">{Math.round(scale * 100)}%</span>
         <Tooltip title="Zoom In">
           <ZoomInOutlined className="zoom-icon" onClick={zoomIn} />
         </Tooltip>
       </div>
       </div>
      )}
      <Document
        className="pdf"
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={() => (
          <Progress
            className="pdf-progress"
            percent={Math.ceil((loadingProgress.loaded * 100) / loadingProgress.total)}
          />
        )}
        onLoadProgress={(e) => setLoadingProgress(e)}
      >
        <Page
          pageNumber={currPage}
          height={pageInfo?.height}
          width={pageInfo?.width}
          scale={scale}
        />
      </Document>
    </div>
  );
};
