import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "./project.scss";
import { Loading, PdfViewer } from "../../components";
import { ISources, uploadFileFolderService } from "../../api";
import { openNotificationWithIcon } from "../../utils";
import Analysis from "../../components/file-details/Analysis";
import { useDispatch, useSelector } from "react-redux";
import { resetQuestionAnswerState, RootState } from "../../store";
import { useParams } from "react-router-dom";
import { useProjectSettings } from "../../hooks";
import { ChatQuesAns } from "../chat/chat-container";

const SingleDocAskAuto = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");
  const { documentUuid, projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isQuesAnsLoading, setIsQuesAnsLoading] = useState(false);
  const [chatSource, setChatSource] = useState<ISources>({} as ISources);
  const [showHelp, setShowHelp] = useState(false);
  const [showChatAside, setShowChatAside] = useState(false);
  const [hideChatHistory, setHideChatHistory] = useState(true);
  const { topicId } = useSelector((state: RootState) => state.fileFolderUpload);
  const dispatch = useDispatch();
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);

  const getSignedUrlByPath = async () => {
    try {
      setIsLoading(true);
      const { data } = await uploadFileFolderService.getSignedUrlByUuid(documentUuid!);
      setFileUrl(data.signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSignedUrlByPath();
    return () => {
      dispatch(resetQuestionAnswerState());
    };
  }, [dispatch, documentUuid]);

  const { fetchQAConfiguration, qaConfiguration, loading } = useProjectSettings();
  useEffect(() => {
    fetchQAConfiguration(projectId);
  }, [fetchQAConfiguration, projectId]);

  if (loading) return <Loading />;

  return (
    <div className="file-detail-container">
      <div style={{ flex: 1, flexBasis: "10vw", width: "50%" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <PdfViewer
            pdf={fileUrl}
            displayPagination
            setCurrPage={setCurrentPage}
            currPage={currentPage}
          />
        )}
      </div>
      <Tabs centered defaultActiveKey="1" tabBarGutter={10} className="tabView">
        {qaConfiguration.dataFields ? (
          <Tabs.TabPane tab={<div className="tabView-title">{project.displayName=="Prepay and Postpay Audit Copilot" ? "Analysis Report": "Analysis"}</div>} key="1">
            <Analysis />
          </Tabs.TabPane>
        ) : (
          <></>
        )}
        <Tabs.TabPane tab={<div className="tabView-title">Ask Auto</div>} key="2">
          <ChatQuesAns
            isSingleDoc={true}
            documentUuid={documentUuid}
            qaConfiguration={qaConfiguration}
            isQuesAnsLoading={isQuesAnsLoading}
            setIsQuesAnsLoading={setIsQuesAnsLoading}
            setChatSource={setChatSource}
            fileterOnMetadata={false}
            selectedFile="All"
            topicId={topicId || 0}
            showHelp={showHelp}
            setShowHelp={setShowHelp}
            setHideChatHistory={setHideChatHistory}
            setShowChatAside={setShowChatAside}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default SingleDocAskAuto;
