export const SEARCH_KEYWORD_DELIMINATOR = ",";

export const ROLES_NAME_AND_CODE = [
  { name: "SUPER ADMIN GROUP", code: 1 },
  { name: "DATA SCIENTIST GROUP", code: 2 },
];

export const COVERAGE_DATA= "Coverage for 81443 : No (7a32a6f8-6ecc-478b-b8ba-8c2eae10d1c7)"

export const DOCUMENT_COVERAGE_DATA= "Coverage for 90966 : No (7a32a6f8-6ecc-478b-b8ba-8c2eae10d1c7)"

export const NEW_SOURCES={
  "documentUUID": "bcf1d982-6e08-452f-9b2a-e11980a8fc39",
  "pages": [             
        {
          "chunkUUID": "7a32a6f8-6ecc-478b-b8ba-8c2eae10d1c7",
          "offsets": [
              {
                  "begin": 1565,
                  "end": 2075
              }
          ],
          "pageNo": 9
      }
  ],
  "fileName": "Genetic Testing for Carrier Screening.pdf"   
}

export const SAMPLE_CODE = 81443
export const DOCUMENT_CODE = 90966
